@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Montserrat:wght@100;400&display=swap');

header {
  width: 100%;
  background-color: black;
  color: white;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.list {
  text-decoration: none;
}

.list ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.list ul li {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  transition: 0.2s;
}

.list ul li:hover {
  color:#7511DE;
}

.brand img {
  width: 80px;
  height: auto;
  margin: 8px auto;
  /* padding: 13px; */
}

.heroDiv{
	display:flex;
	justify-content:center;
	align-items:center;
	gap:10px;
}

.heroDiv h2{
	font-family: 'Bungee Spice', sans-serif;
	font-size: 35px;
	background: url(https://www.scdn.co/i/free/bubbles-dktp.svg) center -138px / 100% 1068px no-repeat, linear-gradient(-45deg, rgb(108, 14, 224) -5%, rgb(240, 55, 166));
	background-size: cover;
	background-position: center;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}

hr{
	height: 15px;
}

@media only screen and (max-width: 650px){
	.brand img{
		width: 50px;
		margin: auto;		
	}

	.heroDiv h2{
		font-size: 20px;
		margin: 0;
	}

	.heroDiv{
		gap: 5px;
		margin: auto;
	}

	.list{
		margin: auto;
	}

	.list ul{
		padding: 0;
	}

	.list ul li{
		font-size: 12px;
		padding: 5px 10px ;
	}
}
