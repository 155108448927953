.prosContainer{
	width: 80%;
	margin: 5rem auto;
	text-align: center;
}

.prosContainer h1{
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 4rem;
}

.proList{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-content: center;
	gap: 20px;
	align-items: flex-start;
}

.proCard{
	width: 20%;
    display: flex;
	gap: 1rem;
	flex-direction: column;
    /* flex-wrap: wrap; */
    align-content: center;
    justify-content: center;
    align-items: center;
}

.proCard img{
	width: 150px;
}

.cardText h3{
	line-height: 25px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.cardText p{
	font-weight: 400;
}


@media only screen and (max-width: 650px){
	.prosContainer{
		margin: 2rem auto;
		width: 100%;
		text-align: left;
	}

	.prosContainer h1{
		font-size: 28px;
		text-align: center;
	}

	.proList{
		flex-direction: column;
		width: 90%;
		margin: auto;
	}

	.proCard{
		width: 100%;
		flex-direction: row;
	}

	.proCard img{
		width: 100px;
	}

	/* .cardText{
		width: 70%;
	} */
	
	.cardText h3{
		font-size: 22px;
		margin-bottom: 0;
	}
}