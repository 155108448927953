footer {
    background-color: black;
    color: white;
}

.footerContainer {
    width: 75%;
    padding: 5rem 0;
    margin: auto;
}

.linkList {
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	/* gap: 20px; */
}

.footer-logo{
	text-align: center;
}

.footer-logo img {
	width: 150px;
}

.footer-logo h2{
	font-weight: 500;
}

.Items {
    line-height: 30px;
}

.Items p {
    font-weight: 700;
    color: #919496;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
}

.Items a {
    color: white;
    font-weight: 600;
}

.Items a:hover {
    color: #7511DE;
}

.socilaMedia {
    display: flex;
    justify-content:space-between;
    align-items: center;
    gap: 15px;
}

.socilaMedia .icon {
    height: auto;
    background-color: #222326;
    font-size: 25px;
    padding: 15px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    margin: 0;
}

.socilaMedia .icon:hover {
    color: #7511DE;
}

.usefullLinks {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 5rem;
}

.btmLinks {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
	gap: 10px;
    flex-wrap: wrap;
}

.btmLinks a {
    color: #919496;
    font-size: 12px;
}

.btmLinks a:hover {
    color: #7511DE;
}

.credit {
    display: flex;
	gap: .8rem;
    flex-direction: column;
    text-align: right;
    color: #919496;
    font-size: 14px;
    font-weight: 400;
}

.credit small:nth-child(1) {
    display: flex;
	gap: 5px;
    align-items: center;
}


@media only screen and (max-width: 650px) {
    .footerContainer {
        width: 90%;
        margin: 0 auto;
    }

    .footer-logo {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 1rem;
    }

    .footer-logo img {
        width: 80px;
        height: auto;
    }
	
	.footer-logo h2{
		font-family: 'Bungee Spice', sans-serif;
		font-size: 25px;
		background: url(https://www.scdn.co/i/free/bubbles-dktp.svg) center -138px / 100% 1068px no-repeat, linear-gradient(-45deg, rgb(108, 14, 224) -5%, rgb(240, 55, 166));
		background-size: cover;
		background-position: center;
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
	}

    .linkList {
        flex-direction: column;
    }

	.itemsList{
		padding-bottom: 10px;
	}

    .socilaMedia {
        margin-top: 1.5rem;
    }

    .usefullLinks {
        margin-top: 2rem;
        gap: 20px;
		align-items: flex-start;
		flex-direction: column-reverse;
    }

    .btmLinks {
        gap: 10px;
    }

    .credit {
        gap: 10px;
        text-align: left;
    }
}

@media only screen and (min-width: 650px){
	
}
