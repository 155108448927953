.content {
  background: url(https://www.scdn.co/i/free/lets-play.svg) left -10px bottom -160px /
      100% 1114px no-repeat,
    linear-gradient(-45deg, rgb(108, 14, 224) 16%, rgb(240, 55, 166))
      rgb(108, 14, 224);
  padding: 8rem 0;
  text-align: center;
}

.content h1 {
  font-size: 5rem;
  color: white;
  letter-spacing: -1px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin: 0 auto 40px;
}

@media only screen and (max-width: 650px){
	.content{
		padding: 7rem 0;
		background: url(https://www.scdn.co/i/free/lets-play.svg) center center / 100% no-repeat, linear-gradient(-155deg, rgb(108, 14, 224) 55%, rgb(240, 55, 166) 115%) rgb(108, 14, 224); 
	}

	 .content h1{
		font-size: 3rem;
		letter-spacing: 0px;
	 }
}
