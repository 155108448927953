nav {
  background: url(https://www.scdn.co/i/free/bubbles-dktp.svg) center -138px / 100%
      1068px no-repeat,
    linear-gradient(-45deg, rgb(108, 14, 224) -5%, rgb(240, 55, 166));
}

.navDiv{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 70vh;
	margin: auto;
	padding: 2rem;
	gap: 4rem;
}

.navDiv img{
	width: 460px;
	height: auto;
}

.navText h1{
	font-size: 4rem;
	font-weight: 800;
	color: white;
	letter-spacing: -2px;
	line-height: 75px;
	margin: 0;
	text-transform: uppercase;
}

.navText h2{
	font-size: 3rem;
	font-weight: 500;
	color: white;
	letter-spacing: -2px;
	line-height: 60px;
	margin: .7rem auto 3rem;
}

@media only screen and (max-width: 650px){
	nav{
		background:  url(https://www.scdn.co/i/free/bubbles-mobile.svg) center center / 100% 927px no-repeat, linear-gradient(-45deg, rgb(108, 14, 224) -5%, rgb(240, 55, 166)) rgb(108, 14, 224);
	}

	.navDiv{
		flex-direction: column;
		height: auto;
		padding: 2.5rem 0;
		width: 100%;
	}
	
	.navDiv img{
		display: none;
	}
	
	.navText{
		width: 80%;
		margin: auto;
		padding: 2.8rem 1rem 2rem;
	}

	.navText h1{
		font-size: 3.6rem;
		line-height: 55px;
	}

	.navText h2{
		width: 80%;
		margin: 15px 0 45px;
		font-size: 2rem;
		line-height: 35px;
		letter-spacing: 0px;
	}
}