*{
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body{
	width: 100%;
	height: 100%;
	/* background-image:linear-gradient(rgba(0, 0, 0, 0.405), rgba(0, 0, 0, 0.373)), url(/public/images/sky-background.webp);
	background-size: cover;
	background-position: center; */
}
::-webkit-scrollbar {
	display: none;
  }

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
	.heroHeading{
	font-family: 'Bungee Spice', sans-serif;
	background: url(https://www.scdn.co/i/free/bubbles-dktp.svg) center -138px / 100% 1068px no-repeat, linear-gradient(-45deg, rgb(108, 14, 224) -5%, rgb(240, 55, 166));
	background-size: cover;
	background-position: center;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	}
	.space-right{
		border-right: 16px solid transparent;
	}
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
}
