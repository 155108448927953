/* JOIN WAITLIST BUTTON */

.waitlistButton {
    position: relative;
    overflow: hidden;
    height: 3rem;
    padding: 0 2rem;
    font-weight: 500;
    border-radius: 1.5rem;
    background: white;
    background-size: 400%;
    color: black;
    border: 1px solid black;
    cursor: pointer;
}

.waitlistButton:hover {
    color: white;
    border: 1px solid transparent;
}

.waitlistButton:hover::before {
    transform: scaleX(1);
}

.waitlistButton a {
    position: relative;
    z-index: 1;
}

.waitlistButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(82.3deg,
            rgba(150, 93, 233, 1) 10.8%,
            rgba(99, 88, 238, 1) 94.3%);
    transition: all 0.475s;
}