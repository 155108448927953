.queryContainer{
	margin: 2rem auto;
	width: 75%;
	
}

.queryFlex{
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	padding: 2rem;
	gap: 4rem;
}

.queryFlex img{
	width: 425px;
}

.queryFlex h2{
	font-size: 30px;
	text-align: center;
	/* font-family: 'Bungee Spice', sans-serif; */
	line-height: 34px;
	letter-spacing: -1px;
	background: url(https://www.scdn.co/i/free/bubbles-dktp.svg) center -138px / 100% 1068px no-repeat, linear-gradient(-45deg, rgb(108, 14, 224) -5%, rgb(240, 55, 166));
	background-size: cover;
	background-position: center;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}

@media only screen and (max-width: 650px){

	.queryContainer{
		width: 100%;
	}

	.queryFlex{
		flex-direction: column;
		padding: 1rem;
		gap: 20px;
	}

	.queryFlex img{
		width: 270px;
	}

	.queryFlex h2{
		text-align: center;
		font-size: 26px;
	}
}