.textContainer {
  background: url(https://www.scdn.co/i/free/bubbles-dktp.svg) center -138px / 100%
      1068px no-repeat,
    linear-gradient(-45deg, rgb(108, 14, 224) -5%, rgb(240, 55, 166));
	padding: 8rem 0;
	text-align: center;
}

.textContainer p{
	font-size: 3rem;
	color: white;
	letter-spacing: -0px;
	display: flex;
	justify-content: center;
	font-weight:800;
	margin: 0 auto;
	width: 75%;
}

.textContainer p:nth-child(2){
	font-size: 2rem;
	line-height: 40px;
	font-weight: 400;
	letter-spacing: -1px;

}

/* .textContainer a{
	background-color: white;
	color: black;
	font-size: 15px;
	padding: 15px 35px;
	border-radius: 30px;
	font-weight: 500;
	margin: 30px;
}

.textContainer a:hover{
	box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
} */


@media only screen and (max-width: 650px){
.textContainer{
	padding: 6rem 0;
	background: url(https://www.scdn.co/i/free/lets-play.svg) center center / 100% no-repeat, linear-gradient(-155deg, rgb(108, 14, 224) 55%, rgb(240, 55, 166) 115%) rgb(108, 14, 224); 
}

	.textContainer p{
		font-size: 2rem;
		font-weight: 600;
		line-height: 40px;
		width: 90%;
		margin: 0px auto 10px;
	}

	.textContainer p:nth-child(2){
		font-size: 1.5rem;
		line-height: 30px;
		font-weight: 400;
		letter-spacing: -0.5px;

	}
}
